<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="amber" class="elevation-0 px-3">
          <span class="headline">Actualizar zona</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="nombre" :validator="$v.zone.name">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Nombre de la zona"
                    placeholder="Nombre de la zona. Ej.: Zona 1"
                    required
                    filled
                    rounded
                    v-model="zone.name"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="descripcion" :validator="$v.zone.description">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Descripción"
                    placeholder="Ingrese la descripción"
                    required
                    filled
                    rounded
                    v-model="zone.description"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="imagen">
                  <v-img
                    :src="
                      zone.tempURL != '' ? zone.tempURL : `media/bg/vacio.png`
                    "
                    style="object-fit: contain"
                    class="my-5 mx-auto rounded"
                    width="180"
                    height="180"
                  ></v-img>
                  <v-alert
                    text
                    prominent
                    dense
                    type="warning"
                    v-if="zone.tempURL == ''"
                    >No hay una imagen registrada</v-alert
                  >
                  <v-file-input
                    v-model="image"
                    type="file"
                    accept="image/*"
                    placeholder="Agregar imagen de la zona"
                    label="Imagen"
                    required
                    filled
                    rounded
                    @change="selectImage"
                    @click:clear="clearImagePreview()"
                  ></v-file-input>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="amber"
            dark
            depressed
            :loading="isLoading"
            @click="submit"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import zoneRepository from "@/repositories/zoneRepository";
import { alphanumeric } from "@/core/plugins/vuelidate";

export default {
  name: "UpdateZone",

  data() {
    return {
      isLoading: false,
      dialog: false,
      image: null,
      imagePreview: "",
      tempZone: {},
    };
  },
  props: {
    zone: {
      type: Object,
    },
  },
  methods: {
    update() {
      /*
        We format the data to be able to add the new one depending on whether the image changes or not, 
        if it changes we add the new image, otherwise we keep the same
        */
      this.tempZone = {
        id: this.zone.id,
        name: this.zone.name,
        description: this.zone.description,
        image: this.zone.image,
      };
      if (!!this.image) {
        this.tempZone.image = this.image;
      }
      this.isLoading = true;
      zoneRepository
        .updateZones(this.tempZone.id, this.objectToFormData(this.tempZone))
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.$emit("update", {
              obj: data.data,
              id: this.zone.id,
            });
            this.$emit("clean-selection");
            this.closeModal();
          }
        })
        .catch((error) => {
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.dir(error.response.data.errors);
            console.info(error.response.status);
            console.dir(error.response.headers);
            this.fireToast({
              icon: "error",
              title: "No ha sido posible crear el registro 🙁",
            });
            return;
          }
          if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.error(error.request);
            this.fireToast({
              icon: "error",
              title: "Ocurrió un error al comunicarse con el servidor 🙁",
            });
            return;
          }
          // Something happened in setting up the request and triggered an Error
          console.error("Error", error.message);
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.resetZone();
    },
    resetZone() {
      (this.zone.name = ""),
        (this.zone.description = ""),
        this.clearImagePreview();
    },
    //image selection and cleanup functions
    async selectImage(e) {
      const file = e;
      if (!file) return;
      const readData = (f) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(f);
        });

      const data = await readData(file);
      this.zone.tempURL = data;
    },
    async clearImagePreview() {
      this.zone.image = null;
      this.zone.tempURL = "";
      this.imagePreview = "";
      this.image = null;
    },
  },
  validations: {
    zone: {
      name: {
        required,
        maxLength: maxLength(255),
        alphanumeric,
      },
      description: {
        required,
        maxLength: maxLength(255),
        alphanumeric,
      },
    },
  },
};
</script>
